export const TOGGLE_WHISHLIST = 'TOGGLE_WHISHLIST';
export const CLEAR_WHISHLIST = 'CLEAR_WHISHLIST';
export const SET_WHISHLIST = 'SET_WHISHLIST';

export function toggleWhishlist(item) {
  return {
    type: TOGGLE_WHISHLIST,
    item,
  };
}

export function clearWhishlist(item) {
  return {
    type: CLEAR_WHISHLIST,
  };
}

export function setWhishlist(list) {
  return {
    type: SET_WHISHLIST,
    list,
  };
}
